<template>
  <div class="withdraw-funds-wrap" id="withdraw-funds-wrap">
    <NavBack text="Cancel" :action="goBack"></NavBack>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered">Withdraw funds</div>
      <div class="main has-background-white">
        <div class="section-title has-text-centered" id="anchor">Keep in mind, you can withdraw a maximum of ${{formattedDailyLimit}} per day{{weeklyLimitText}} ⏰</div>
        <div class="section-subtitle has-text-centered has-text-weight-semibold">Available for withdrawal
          <img src="@assets/icons/info.svg" alt="hint" class="hint-for-withdrawal is-clickable" @click="showWithdrawModal = true">
        </div>
        <div class="available-funds has-text-centered">
          <span class="has-text-blue sign has-text-weight-semibold">$</span>
          <span class="has-text-blue value">{{formattedAvailableFunds}}</span>
        </div>
        <div class="label-of-input  amount has-text-weight-semibold pl-3">Amount to withdraw:</div>
        <InputInTwoDecimals
          scrollAnchorId="anchor"
          pageId="withdraw-funds-wrap"
          :isError="inputHasError"
          @onInput="onInput"
        ></InputInTwoDecimals>
        <div class="error-for-amount" v-if="inputHasError">{{errorMessage}}</div>
        <div class="label-of-input has-text-weight-semibold pl-3">Move to:</div>
        <b-select class="account-list" v-model="selectedAccount" expanded>
          <option
            v-for="account in accounts"
            :value="account.hashid"
            :key="account.hashid">
            {{ account.accountName }} ****{{account.accountNumberLast4}}
          </option>
        </b-select>
        <div class="error-for-accounts" v-if="selectedAccount === ''">Oops! No account connected.
          <span class="has-text-primary has-text-weight-semibold is-clickable" @click="toLinkBankPage">Click here</span>
          to connect your bank account.</div>
        <div class="waiting-period has-text-lavender has-text-centered">
          Please allow for 3 - 5 business days for your withdrawal to be processed.
        </div>
      </div>
      <BottomButtons
        nextButtonText="Withdraw"
        backButtonText="Cancel"
        :nextDisabled="!withdrawAmount || inputHasError || !selectedAccount"
        :loading="nextButtonIsLoading"
        :showSteps="false"
        @goNext="withdraw"
        @goBack="goBack"
      ></BottomButtons>
    </div>
    <div :class="`modal withdraw-modal px-4 ${showWithdrawModal ? 'is-active' : ''}`">
      <div class="modal-background is-clickable" @click="closeWithdrawModal"></div>
      <div class="modal-content has-background-white">
        <div class="modal-header has-text-centered">What does ‘available for withdrawal’ mean?</div>
        <div class="modal-text">
          This is the total amount of money that is available in your addy wallet to withdraw to your financial institution.
          Any money currently invested in a property cannot be withdrawn 🙊!
        </div>
        <div class="modal-text">
          Only the money that has been in your wallet for 7+ business days can be withdrawn.
          🗓&nbsp;This is to confirm that funds added to your account have successfully transferred to addy!
        </div>
        <div class="modal-text">
          Any funds granted via a promotion or bonus are not available for withdrawal until it has been invested in a property 🙌.
        </div>
        <b-button type="is-blue button-ok" class="has-text-weight-bold is-clickable" @click="closeWithdrawModal" expanded>Gotcha</b-button>
      </div>
    </div>
    <Flash
      title="Sorry!"
      :content="exceedsMaxErrorMessage"
      :show="showFlash"
      :hideFlash="hideFlashAction"
    ></Flash>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import InputInTwoDecimals from '@components/input/currency-input.vue'
import Flash from '@components/modal/slide-down-flash.vue'
import axios from '@lib/axios/middleware'
import walletRoutes from '@utils/mixins/wallet-routes.js'
import { getConnectedAccounts, getWalletDetails, withdrawFunds } from '@api/wallet'
import { truncate, formatter } from '@utils/common-methods/numberFormatter'
import { embeddedInIos } from '@utils/common-methods/common'

export default {
  mixins: [walletRoutes],
  components: {
    NavBack,
    MenuAndLogo,
    BottomButtons,
    InputInTwoDecimals,
    Flash,
  },
  data() {
    return {
      availableFunds: 0,
      inputHasError: false,
      nextButtonIsLoading: false,
      showWithdrawModal: false,
      selectedAccount: undefined,
      accounts: [],
      withdrawAmount: 0,
      showFlash: false,
      exceedsMaxErrorMessage: '',
      timer: null,
      dailyLimit: 500,
      weeklyLimit: 3500,
    }
  },
  computed: {
    truncatedAvailableFunds() {
      return truncate(this.availableFunds, 2)
    },
    formattedAvailableFunds() {
      return formatter(this.truncatedAvailableFunds)
    },
    formattedDailyLimit() {
      return formatter(this.dailyLimit, 0)
    },
    formattedWeeklyLimit() {
      return formatter(this.weeklyLimit, 0)
    },
    insignificantWeeklyLimit() {
      return this.weeklyLimit === (this.dailyLimit * 7)
    },
    weeklyLimitText() {
      return this.insignificantWeeklyLimit ? '' : `, up to a maximum of $${this.formattedWeeklyLimit} per week`
    },
    errorMessage() {
      let message = 'Sorry, the minimum withdrawal amount is $5.00'
      if (this.withdrawAmount > Number(this.truncatedAvailableFunds)) message = 'Sorry, the withdrawal amount exceeds your available wallet balance.'
      if (this.withdrawAmount > this.dailyLimit) message = `You can only withdraw a maximum of $${this.formattedDailyLimit} every 24 hours.`
      return message
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      axios.all([
        getConnectedAccounts(),
        getWalletDetails(),
      ]).then((data) => {
        if (data[0].success) this.handleAccountList(data[0].data.accountList)
        if (data[1].success) {
          this.availableFunds = Number(data[1].data.availableWithdrawalBalance)
          this.dailyLimit = Number(data[1].data.dailyWithdrawalLimit)
          this.weeklyLimit = Number(data[1].data.weeklyWithdrawalLimit)
        }
      })
    },
    handleAccountList(accountList) {
      this.accounts = accountList.filter((account) => {
        return account.status === 'verified'
      })
      this.selectedAccount = this.accounts[0] ? this.accounts[0].hashid : ''
    },
    onInput(value) {
      this.withdrawAmount = value
      this.validateAmount()
    },
    validateAmount() {
      this.inputHasError = (this.withdrawAmount > Number(this.truncatedAvailableFunds)) || (this.withdrawAmount < 5) || this.withdrawAmount > this.dailyLimit
    },
    goBack() {
      if (embeddedInIos()) window.webkit.messageHandlers.closeWebview.postMessage(true)
      else this.$router.go(-1)
    },
    withdraw() {
      if (this.inputHasError || !this.selectedAccount) return
      this.nextButtonIsLoading = true
      withdrawFunds(this.withdrawAmount, this.selectedAccount).then((result) => {
        if (!result.success) this.promptError(result.error)
        else this.$router.push('/wallet/withdrawalSuccess')
      })
    },
    closeWithdrawModal() {
      this.showWithdrawModal = false
    },
    promptError(message) {
      this.exceedsMaxErrorMessage = message
      this.showFlash = true
      this.nextButtonIsLoading = false

      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.hideFlashAction()
      }, 8000)
    },
    hideFlashAction() {
      this.showFlash = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.withdraw-funds-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  overflow: auto;
  padding-bottom: 30px;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      font-size: 16px;
      border-radius: 12px;
      box-shadow: 0px 30px 50px rgba(215, 145, 51, 0.11);
      margin-bottom: 30px;
      padding: 22px 14px;
      .section-title {
        line-height: 20px;
        padding-bottom: 28px;
      }
      .section-subtitle {
        margin-bottom: 8px;
        .hint-for-withdrawal {
          vertical-align: middle;
          margin-left: 6px;
        }
      }
      .available-funds {
        .sign {
          font-size: 18px;
          margin-right: 4px;
          vertical-align: top;
          line-height: 29px;
        }
        .value {
          font-size: 40px;
        }
      }
      .label-of-input {
        font-size: 16px;
        margin-top: 18px;
        padding-bottom: 12px;
        &.amount {
          margin-top: 30px;
        }
      }
      .error-for-amount,
      .error-for-accounts {
        padding-left: 8px;
        font-size: 14px;
        line-height: 16px;
        margin-top: 4px;
        color: #C91717;
        .is-link {
          color: #3f75d5;
        }
      }
      .waiting-period {
        margin-top: 12px;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
  .withdraw-modal {
    .modal-content {
      position: relative;
      border-radius: 12px;
      padding: 24px;
      max-height: unset;
      max-width: $mobile-wrapper-width;
      .modal-header {
        font-weight: 600;
        font-size: 28px;
        padding-bottom: 20px;
      }
      .modal-text {
        font-size: 16px;
        padding-bottom: 30px;
        line-height: 20px;
      }
      .button-ok {
        font-size: 16px;
        height: 43px;
        border-radius: 12px;
      }
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .withdraw-funds-wrap {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
  }
}
</style>
<style scoped>
.account-list >>> .select,
.account-list >>> select {
  height: 51px;
}
.account-list >>> select {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.account-list >>> .select::after {
  border-color: #4A26AA;
  border-width: 2px;
}
</style>
